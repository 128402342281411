// import { ref, watch, computed } from '@vue/composition-api'
import { ref, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function useComponentsList() {
  // Use toast
  const toast = useToast()

  const refComponentListTable = ref(null)

  // Table Handlers
  const tableColumnsComponent = [
    {
      key: 'name',
      label: i18n.t('beverages.component'),
      formatter: title,
      sortable: true,
    },
    { key: 'unit', label: i18n.t('beverages.unit'), sortable: true },
    /*
    {
      key: 'company_id',
      label: 'Company',
      formatter: title,
      sortable: true,
    },
    */
    { key: 'actions', label: i18n.t('common.actions') },
  ]
  const perPageComponent = ref(10)
  const totalComponents = ref(0)
  const currentPageComponent = ref(1)
  const perPageOptionsComponent = [10, 25, 50, 100]
  const searchQueryComponent = ref('')
  const sortByComponent = ref('id')
  const isSortDirDescComponent = ref(true)
  const roleFilter = ref(null)
  const companyFilterComponent = ref(null)
  const statusFilter = ref(null)

  const dataMetaComponents = computed(() => {
    const localItemsCount = refComponentListTable.value ? refComponentListTable.value.localItems.length : 0
    return {
      from: perPageComponent.value * (currentPageComponent.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageComponent.value * (currentPageComponent.value - 1) + localItemsCount,
      of: totalComponents.value,
    }
  })

  const refetchDataComponent = () => {
    refComponentListTable.value.refresh()
  }

  /*
  watch([currentPageComponent, perPageComponent, searchQueryComponent, roleFilter, companyFilter, statusFilter], () => {
    refetchDataComponent()
  })
  */

  const fetchComponents = (ctx, callback) => {
    store
      .dispatch('app-component/fetchComponents', {
        q: searchQueryComponent.value,
        perPage: perPageComponent.value,
        page: currentPageComponent.value,
        sortBy: sortByComponent.value,
        sortDesc: isSortDirDescComponent.value,
        role: roleFilter.value,
        company: companyFilterComponent.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { components, total } = response.data

        callback(components)
        totalComponents.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching components list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const modelData = ref(null)
  const isAddNewComponentSidebarActive = ref(0)

  const duplicateComponent = item => {
    modelData.value = item
    isAddNewComponentSidebarActive.value = 2
  }

  const deleteComponent = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Component <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-component/deleteComponent', { id: item.id })
          .then(() => {
            refetchDataComponent()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Component ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Component delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  /* */
  const roleOptions = ref([])
  store.dispatch('app-beverage/fetchRoles')
    .then(response => {
      // console.log('RESP', response.data)
      roleOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-beverage/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const unitOptions = ref([
    { label: 'шт', value: 'pcs' },
    { label: 'мл', value: 'ml' },
    { label: 'г', value: 'g' },
  ])

  /* */

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveComponentRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveComponentRoleIcon = role => {
    if (role === 'subscriber') return 'ComponentIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ComponentIcon'
  }

  const resolveComponentRoleText = role => {
    try {
      return roleOptions.value.find(o => o.value === role).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveComponentUnitText = unit => {
    try {
      return unitOptions.value.find(o => o.value === unit).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveComponentStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveComponentCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchComponents,
    tableColumnsComponent,
    perPageComponent,
    currentPageComponent,
    totalComponents,
    dataMetaComponents,
    perPageOptionsComponent,
    searchQueryComponent,
    sortByComponent,
    isSortDirDescComponent,
    refComponentListTable,

    resolveComponentRoleVariant,
    resolveComponentRoleIcon,
    resolveComponentRoleText,
    resolveComponentUnitText,
    resolveComponentStatusVariant,
    resolveComponentCompanyText,
    refetchDataComponent,

    // Extra Filters
    roleFilter,
    companyFilterComponent,
    statusFilter,

    roleOptions,
    companyOptions,

    unitOptions,

    deleteComponent,
    duplicateComponent,

    modelData,
    isAddNewComponentSidebarActive,

  }
}
