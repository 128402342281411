<template>

  <div>

    <beverage-list-add-new
      :is-add-new-beverage-sidebar-active.sync="isAddNewBeverageSidebarActive"
      :role-options="roleOptions"
      :company-options="companyOptions"
      @refetch-data="refetchData"
    />

    <component-list-add-new
      :is-add-new-component-sidebar-active.sync="isAddNewComponentSidebarActive"
      :unit-options="unitOptions"
      :company-options="companyOptions"
      :model-data="modelData"
      @refetch-data="refetchDataComponent"
    />

    <beverages-list-filters
      :company-filter.sync="companyFilter"
    />

    <b-tabs
      pills
    >

      <b-tab
        v-if="$can('show', 'Beverages_Beverages')"
        active
      >
        <template #title>
          <feather-icon
            icon="PlanogramIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('beverages.beverages') }}</span>
        </template>
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  />
                  <b-button
                    variant="primary"
                    @click="$router.push({ name: 'apps-beverages-edit', params: { id: 'new', clone: false }})"
                  >
                    <span class="text-nowrap">{{ $t('beverages.add_beverage') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refBeverageListTable"
            class="position-relative"
            :items="fetchBeverages"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Beverage -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to_="{ name: 'apps-beverages-view', params: { id: data.item.id } }"
                  :to="{ name: 'apps-beverages-edit', params: { id: data.item.id, clone: false }}"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <!--small class="text-muted">@{{ data.item.beveragename }}</small-->
              </b-media>
            </template>

            <!-- Column: Role -->
            <template #cell(role_id)="data">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveBeverageRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveBeverageRoleVariant(data.item.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ data.item.role }} {{ resolveBeverageRoleText(data.item.roleId) }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveBeverageCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveBeverageStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-beverages-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item-->

                <b-dropdown-item :to="{ name: 'apps-beverages-edit', params: { id: data.item.id, clone: true } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("common.duplicate") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteBeverage(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("common.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalBeverages"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>

      <b-tab v-if="$can('show', 'Beverages_Components')">
        <template #title>
          <feather-icon
            icon="PlanogramIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('beverages.components') }}</span>
        </template>
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perPageComponent"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptionsComponent"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryComponent"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  />
                  <b-button
                    variant="primary"
                    @click="isAddNewComponentSidebarActive = 1"
                  >
                    <span class="text-nowrap">{{ $t('beverages.add_component') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refComponentListTable"
            class="position-relative"
            :items="fetchComponents"
            responsive
            :fields="tableColumnsComponent"
            primary-key="id"
            :sort-by.sync="sortByComponent"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescComponent"
          >

            <!-- Column: Component -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </span>
                <!--small class="text-muted">@{{ data.item.componentname }}</small-->
              </b-media>
            </template>

            <!-- Column: Role -->
            <template #cell(role_id)="data">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveComponentRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveComponentRoleVariant(data.item.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ data.item.role }} {{ resolveComponentRoleText(data.item.roleId) }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveComponentCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(unit)="data">
              <div class="text-nowrap">
                {{ resolveComponentUnitText(data.item.unit) }}
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveComponentStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-components-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item-->

                <b-dropdown-item @click="duplicateComponent(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("common.duplicate") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteComponent(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("common.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageComponent"
                  :total-rows="totalComponents"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import {
  // BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import store from '@/store'
import BeveragesListFilters from './BeveragesListFilters.vue'
import useBeveragesList from './useBeveragesList'
import useComponentsList from './useComponentsList'
import beverageStoreModule from '../beverageStoreModule'
import componentStoreModule from '../componentStoreModule'
import BeverageListAddNew from './BeverageListAddNew.vue'
import ComponentListAddNew from './ComponentListAddNew.vue'

export default {
  components: {
    BeveragesListFilters,
    ComponentListAddNew,
    BeverageListAddNew,

    BTab,
    BTabs,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-beverage'
    const USER_APP_STORE_MODULE_NAME_2 = 'app-component'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, beverageStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME_2)) store.registerModule(USER_APP_STORE_MODULE_NAME_2, componentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME_2)) store.unregisterModule(USER_APP_STORE_MODULE_NAME_2)
    })

    const isAddNewBeverageSidebarActive = ref(false)

    /*

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchBeverages,
      tableColumns,
      perPage,
      currentPage,
      totalBeverages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBeverageListTable,
      refetchData,

      // UI
      resolveBeverageRoleVariant,
      resolveBeverageRoleIcon,
      resolveBeverageRoleText,
      resolveBeverageStatusVariant,
      resolveBeverageCompanyText,

      // Extra Filters
      roleFilter,
      companyFilter,
      statusFilter,

      roleOptions,
      companyOptions,

      deleteBeverage,

    } = useBeveragesList()

    const {
      fetchComponents,
      tableColumnsComponent,
      perPageComponent,
      currentPageComponent,
      totalComponents,
      dataMetaComponents,
      perPageOptionsComponent,
      searchQueryComponent,
      sortByComponent,
      isSortDirDescComponent,
      refComponentListTable,
      refetchDataComponent,

      // UI
      resolveComponentRoleVariant,
      resolveComponentRoleIcon,
      resolveComponentRoleText,
      resolveComponentStatusVariant,
      resolveComponentCompanyText,
      resolveComponentUnitText,

      // Extra Filters
      // roleFilter,
      // companyFilter,
      companyFilterComponent,
      // statusFilter,

      // roleOptions,
      // companyOptions,
      unitOptions,

      deleteComponent,
      duplicateComponent,

      modelData,
      isAddNewComponentSidebarActive,

    } = useComponentsList()

    watch([currentPage, perPage, searchQuery, roleFilter, companyFilter, statusFilter], () => {
      refetchData()
    })

    watch([currentPageComponent, perPageComponent, searchQueryComponent, roleFilter, companyFilter, statusFilter], () => {
      companyFilterComponent.value = companyFilter.value
      refetchDataComponent()
    })

    return {

      // Sidebar
      isAddNewBeverageSidebarActive,
      isAddNewComponentSidebarActive,

      fetchBeverages,
      fetchComponents,
      tableColumns,
      tableColumnsComponent,
      perPage,
      perPageComponent,
      currentPage,
      currentPageComponent,
      totalBeverages,
      totalComponents,
      dataMeta,
      dataMetaComponents,
      perPageOptions,
      perPageOptionsComponent,
      searchQuery,
      searchQueryComponent,
      sortBy,
      sortByComponent,
      isSortDirDesc,
      isSortDirDescComponent,
      refBeverageListTable,
      refComponentListTable,
      refetchData,
      refetchDataComponent,

      // Filter
      avatarText,

      // UI
      resolveBeverageRoleVariant,
      resolveBeverageRoleIcon,
      resolveBeverageRoleText,
      resolveBeverageStatusVariant,
      resolveBeverageCompanyText,

      resolveComponentRoleVariant,
      resolveComponentRoleIcon,
      resolveComponentRoleText,
      resolveComponentUnitText,
      resolveComponentStatusVariant,
      resolveComponentCompanyText,

      roleOptions,
      companyOptions,
      statusOptions,

      unitOptions,

      // Extra Filters
      roleFilter,
      companyFilter,
      companyFilterComponent,
      statusFilter,

      deleteBeverage,
      deleteComponent,
      duplicateComponent,

      modelData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
