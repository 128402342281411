<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('common.filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.company') }}</label>
          <v-select
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:companyFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useBeveragesList from './useBeveragesList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  setup() {
    const { companyFilter, companyOptions } = useBeveragesList()
    return {
      companyFilter,
      companyOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
