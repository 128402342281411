<template>
  <b-sidebar
    id="add-new-component-sidebar"
    :visible="isAddNewComponentSidebarActive? true: false"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-component-sidebar-active', val? 1 : 0)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('beverages.add_new_component') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('beverages.component_name')"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="componentData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name of component"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contact -->
          <validation-provider
            #default="validationContext"
            name="Unit"
            rules="required"
          >
            <b-form-group
              :label="$t('beverages.unit')"
              label-for="unit"
            >
              <v-select
                v-model="componentData.unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="unitOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="unit"
                _placeholder="Unit of component"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -- >
          <validation-provider
            #default="validationContext"
            name="Company"
            rules="required"
          >
            <b-form-group
              label="Company"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="componentData.companyId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="company"
                _placeholder="Component owner"
              />
              <b-form-invalid-feedback :state="resOk.company.success ? false : getValidationState(validationContext)">
                {{ validationContext.errors[0] }}{{ resOk.company.error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("common.add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("common.cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line
import { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewComponentSidebarActive',
    event: 'update:is-add-new-component-sidebar-active',
  },
  props: {
    isAddNewComponentSidebarActive: {
      type: Number,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    companyOptions: {
      type: Array,
      required: true,
    },
    modelData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  watch: {
    isAddNewComponentSidebarActive() {
      if (this.isAddNewComponentSidebarActive === 2 && this.modelData) {
        this.componentData = this.modelData
        // this.modelData = null
        // console.log(this)
        // this.$emit('resetcomponent-data')
        // console.log(22)
      } else {
        // this.componentData = JSON.parse(JSON.stringify(this.blankComponentData))
      }
    },
  },
  setup(props, { emit }) {
    const blankComponentData = {
      name: '',
      unit: null,
      companyId: null,
    }

    // console.log(props.isAddNewComponentSidebarActive)
    const componentData = ref(JSON.parse(JSON.stringify(blankComponentData)))
    const resetcomponentData = () => {
      componentData.value = JSON.parse(JSON.stringify(blankComponentData))
      // eslint-disable-next-line
      // props.modelData.value = null
    }

    /*
    watch([ref(props.isAddNewComponentSidebarActive)], () => {
    // watch([componentData], () => {
      if (props.modelData.value) resetcomponentData()
    })
    */

    const ok = {
      company: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      resOk.value = ok
      store.dispatch('app-component/addComponent', componentData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-component-sidebar-active', 0)
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  // alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcomponentData)

    return {
      componentData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resOk,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-component-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
